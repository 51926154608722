<template>
  <main class="contact-us">
    <div class="container py-0">
      <div class="contact-us__body-wrapper">
        <h3 class="contact-us__title">CONTACT US</h3>
        <div class="contact-us__body">
          <div class="contact-us__left">
            <h6>How to place an order?</h6>
            <p>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
              eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
              enim ad minim veniam, quis nostrud exercitation ullamco laboris
              nisi ut aliquip ex ea commodo consequat.
            </p>
          </div>
          <div class="contact-us__right">
            <SendEmailForm @sendForm="sendForm" />
          </div>
        </div>
      </div>
      <div class="contact-us__footer">
        <div class="contact-us__footer-wrapper">
          <div class="contact-us__footer-card">
            <h3>Tumar Art – Russia</h3>
            <span>1635 Franklin Street Montgomery</span>
            <span>AL 36104</span>
            <span>Phone: 126-632-2345</span>
          </div>
          <div class="contact-us__footer-card">
            <h3>TumarShop – Kazakhstan</h3>
            <span>1635 Franklin Street Montgomery</span>
            <span>AL 36104</span>
            <span>Phone: 126-632-2345</span>
          </div>
          <div class="contact-us__footer-card">
            <h3>Kyrgies – USA</h3>
            <span>1635 Franklin Street Montgomery</span>
            <span>AL 36104</span>
            <span>Phone: 126-632-2345</span>
          </div>
          <div class="contact-us__footer-card">
            <h3>Livarte Germany</h3>
            <span>1635 Franklin Street Montgomery</span>
            <span>AL 36104</span>
            <span>Phone: 126-632-2345</span>
          </div>
        </div>
        <div class="contact-us__footer-img">
          <img src="/img/map.jpg" alt="map" />
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import SendEmailForm from '@/view/content/store/SendEmailForm'
export default {
  name: 'ContactUs',
  components: {
    SendEmailForm,
  },
  methods: {
    sendForm() {
      // send form
    },
  },
}
</script>
